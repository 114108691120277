import React, {Component} from 'react';
import {Cart, CartItem} from 'wcw-shared';
import InventoryBanner from './InventoryBanner';
import {Carousel} from 'react-responsive-carousel';
import DeliveryComponent from './DeliveryComponent';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ShippingPriceComponent from "./ShippingPriceComponent";
import {Link} from 'react-router-dom';


class AddStockingsComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.toggleAddStockings = this.toggleAddStockings.bind(this);
    }

    toggleAddStockings() {
        this.props.includeStockings(!this.props.addStockings);
    }

    render() {
        if (this.props.sku.xmas) {
            return '';
        }
        const addStockings = this.props.addStockings;
        return <div className="addStockings">
            <label htmlFor={"add_stockings_" + this.props.sku.id}>Include stockings for $5.00
            <input id={"add_stockings_" + this.props.sku.id}
                   type="checkbox"
                   value={addStockings}
                   onClick={this.toggleAddStockings} />
            </label>
        </div>;
    }
}

class DetailComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.addToCart = this.addToCart.bind(this);
        this.goBack = this.goBack.bind(this);
        this.includeStockings = this.includeStockings.bind(this);
        this.state = {
            addStockings: false
        };
    }

    addToCart() {
        this.props.addItem(new CartItem.cartItem(this.props.sku, 1, this.state.addStockings));
    }

    goBack() {
        this.props.history.goBack();
    }

    includeStockings(addStockings) {
        this.setState({addStockings : addStockings});
    }

    render() {
        const sku = this.props.sku;
        if (sku == null) {
            return <div>Loading details...</div>;
        }
        const details = sku.details.map((detail, idx) =>
            <div key={idx}>
                <img alt={detail.description} src={detail.image}/>
            </div>
        );
        let textDescription = sku.description.map((text, idx)=> <p key={idx}>{text}</p>);
        const cartItem = new CartItem.cartItem(this.props.sku, 1, this.state.addStockings);
        const price = Cart.itemEachAmount(cartItem);

        return (
        <div className="catalog">
            <div className="carouselContainer">
                <Carousel width="300px" autoPlay={true} stopOnHover={true} showThumbs={false} infiniteLoop={true}>
                    {details}
                </Carousel>
            </div>
            <div className="description">
                <div className="nameAndInventory">
                    <label className="name">{sku.name}</label>
                    <InventoryBanner sku={sku} />
                </div>
                {sku.video ? <p><Link to={`/video/${sku.id}`}>See fireplace in action</Link></p> : ''}
                {textDescription}
                <ShippingPriceComponent cart={this.props.cart} />
                <DeliveryComponent sku={sku} />
                <p>Have a question? <a href="mailto:woodyscustomwoodwork@gmail.com">Email us</a>.</p>
                <AddStockingsComponent sku={sku} addStockings={this.state.addStockings} includeStockings={this.includeStockings} />
                <div className="priceRow">
                    <button className="link" onClick={this.goBack}>Back</button>
                    <label className="price">{Cart.renderPrice(price)}</label>
                    <button className="addToCart" disabled={sku.available <= 0} onClick={this.addToCart}><i className="fas fa-cart-plus" /> Add</button>
                </div>
            </div>
        </div>
        );
    }
}

export default DetailComponent;
