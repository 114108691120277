import React, {Component} from 'react';
import ItemComponent from './ItemComponent';
import {CartItem} from 'wcw-shared';

class Catalog extends Component{

    constructor(props, context) {
        super(props, context);
        this.addToCart = this.addToCart.bind(this);
    }

    addToCart(sku, addStockings) {
        this.props.addItem(new CartItem.cartItem(sku, 1, addStockings));
    }

    render() {
        const items = this.props.catalog.sort(Catalog.sortSkus).map((sku, idx) =>
            <ItemComponent key={idx} sku={sku} addToCart={this.addToCart} />);

        return (
        <div className="catalog">
            {items}
        </div>
        );
    }

    static sortSkus(a, b) {
        if (a.sort <= b.sort) {
            return -1;
        }
        return 1;
    }
}

export default Catalog;
