var _classCallCheck = require("/home/chad.schellenger/Development/shopwcw/ui/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var Detail = function Detail(description, image) {
  "use strict";

  _classCallCheck(this, Detail);

  this.description = description;
  this.image = image;
};

function createDetail(object) {
  return new Detail(object.description, object.image);
}

module.exports = {
  detail: Detail,
  createDetail: createDetail
};