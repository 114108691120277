import React, {Component} from 'react';
import ReactPlayer from 'react-player'
import {Cart, CartItem} from 'wcw-shared';


class VideoComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.addToCart = this.addToCart.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    addToCart() {
        this.props.addItem(new CartItem.cartItem(this.props.sku));
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const sku = this.props.sku;
        if (sku == null) {
            return <div>Loading details...</div>;
        }
        return (
        <div >
            <ReactPlayer url={sku.video} playing={true} volume={0} muted={true} controls={false} loop={true} width="300px" height="400px" className="video"/>
            <div className="priceRow">
                <button className="link" onClick={this.goBack}>Back</button>
                <label className="price">{Cart.renderPrice(sku.price)}</label>
                <button className="addToCart" disabled={sku.available <= 0} onClick={this.addToCart}><i className="fas fa-cart-plus" /> Add</button>
            </div>
        </div>
        );
    }
}
export default VideoComponent;
