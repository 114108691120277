import React, {Component} from 'react';

class InventoryBanner extends Component{

    render () {
        const sku = this.props.sku;
        if (sku.available <= 0) {
            return <div className="lowInventory">Out of stock</div>;
        }
        if (sku.available < 4) {
            return <div className="lowInventory">Only {sku.available} left!</div>;
        }
        return '';
    }
}
export default InventoryBanner;
