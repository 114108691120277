import React from 'react';
import './Wcw.css';
import CheckoutAgreement from './CheckoutAgreement';
import Catalog from './Catalog';
import CartComponent from './CartComponent';
import CheckoutComponent from "./CheckoutComponent";
import {matchPath, withRouter} from "react-router";
import {Link, Route} from "react-router-dom";
import DetailComponent from "./DetailComponent";
import {Cart, CartItem, filterClassname, Order, Sku} from 'wcw-shared';
import Config from "./Config";
import AdminComponent from './AdminComponent';
import {withCookies} from 'react-cookie';
import Loader from 'react-loader-spinner'
import VideoComponent from "./VideoComponent";

class Wcw extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.acceptAgreement = this.acceptAgreement.bind(this);
        this.addItem = this.addItem.bind(this);
        this.addStocking = this.addStocking.bind(this);
        this.applyCheckoutCode = this.applyCheckoutCode.bind(this);
        this.checkout = this.checkout.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.removeStocking = this.removeStocking.bind(this);
        this.toggleShipping = this.toggleShipping.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.updateStocking = this.updateStocking.bind(this);
        this.validate = this.validate.bind(this);
        this.slideState = this.slideState.bind(this);
        this.changeQuantity = this.changeQuantity.bind(this);
        const urlParams = new URLSearchParams(window.location.search);
        const env = urlParams.get('env') || 'prod';
        const fake = urlParams.get('fake');
        const config = new Config()[env];
        config.configStripe();
        let cart = new Cart.cart();
        const order = new Order.order(cart);
        this.state = {
            cart: cart,
            order: order,
            fake: fake,
            checkoutInProgress: false,
            validationError: null,
            catalog: {},
            config: config,
            flyingIn: false,
            flyingOut: false,
        }
    }

    componentDidMount() {
        let cookieCart = this.props.cookies.get('cart');
        if (cookieCart) {
            console.log("Loading cart from cookies.");
            const { order } = this.state;
            order.cart = Cart.createCart(cookieCart);
            const quantityXmas = order.quantityChristmas();
            for (let i=0; i < quantityXmas; i++) {
                // add 2 stocking names
                console.log("Found xmas!");
                order.stockingNames[order.stockingNames.length] = '';
                order.stockingNames[order.stockingNames.length] = '';
            }
            this.setState({cart : order.cart, order: order});
        }
        fetch(this.state.config.skuEndpoint,{
            method: 'GET',
            mode: 'cors'
        })
        .then(res => res.json(), err => {
            console.log("Unable to load catalog: %s", err.message);
        })
        .then(res => {
            let catalog = {};
            if (this.state.fake != null) {
                const fakeItem = Sku.createSku({
                    id: 'fake',
                    name: this.state.fake,
                    imgSrc: 'images/carpentry.jpg',
                    description: 'Test Product Only',
                    price: 100
                });
                catalog[fakeItem.id] = fakeItem;
            }
            let items = res.Items;
            items.map(item => Sku.createSku(item)).forEach(item => catalog[item.id] = item);
            this.setState({catalog: catalog});
        });
        const {location} = this.props;
        const match = matchPath(location.pathname, {
            path: '/orderComplete/:orderId',
            exact: true,
            strict: false
        });
        if (match != null) {
            console.log("Clearing cart due to order success");
            const { cookies } = this.props;
            const { order } = this.state;
            // save the order to a cookie for review
            cookies.set('order', JSON.stringify(order, filterClassname), { path: '/', maxAge: 604800});
            // construct a new cart
            const cart = new Cart.cart();
            // set it on the order object.
            order.cart = cart;
            // save it to the current cart cookie
            cookies.set('cart', JSON.stringify(cart, filterClassname), { path: '/', maxAge: 604800});
            this.setState({cart: cart, order: order});
        } else {
            console.log("Order not complete...");
        }
    }

    static validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validate() {
        const { cart } = this.state;
        const { order } = this.state;
        const quantityXmas = order.quantityChristmas();
        if (quantityXmas > 0) {
            // Require at least 2 stockings per fireplace
            let countValid = 0;
            order.stockingNames.forEach((s) => {
                if (s) {
                    countValid += 1
                }
            });
            if (countValid > (6 * quantityXmas)) {
                this.setState({validationError: 'Too many stockings filled out!'});
                return false;
            }
            if (countValid < (2 * quantityXmas)) {
                this.setState({validationError: 'Not enough stockings filled out!'});
                return false;
            }
        }
        if (!cart.hasItem()) {
            this.setState({validationError: 'Your cart is empty!'});
            return false;
        }
        if (!order.email) {
            this.setState({validationError: 'Your email is required.'});
            return false;
        }
        if (!Wcw.validateEmail(order.email)) {
            this.setState({validationError: 'Please double check your email is correct.'});
            return false;
        }

        const addr = order.shippingAddress;
        if (!addr.to) {
            this.setState({validationError: 'Your name is required.'});
            return false;
        }
        if (!addr.line1) {
            this.setState({validationError: 'Street address is required.'});
            return;
        }
        if (!addr.city) {
            this.setState({validationError: 'City is required.'});
            return false;
        }
        if (!addr.state) {
            this.setState({validationError: 'State is required.'});
            return false;
        }
        if (!addr.zip) {
            this.setState({validationError: 'Zip is required.'});
            return false;
        }
        this.setState({validationError: null});
        return true;
    }

    checkout() {
        if (!this.validate()) {
            console.log("Refusing to checking due to validation error");
            return false;
        }
        console.log("Starting checkout...");
        this.setState({checkoutInProgress: true});
        fetch(this.state.config.orderEndpoint,{
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(this.state.order, filterClassname)
        })
        .then(
            res => res.json(),
            err => {
                this.setState({
                    validationError: "Unable to begin payment process. Please ensure all fields are correct and try again.",
                    checkoutInProgress: false
                });
                throw err;
            }
        ).then((orderRes) => {
            console.log("Successful order creation... redirecting!");
            window.stripe.redirectToCheckout({
                sessionId: orderRes.stripeSessionId
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                console.log("Unable to redirect to stripe: %s", result);
                this.setState({
                    validationError: result.error.message,
                    checkoutInProgress: false
                });
            });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            console.log("Unable to create order: %s", error.message);
            this.setState({
                validationError: "There was an unknown error. Please try again.",
                checkoutInProgress: false
            });
        });
    }

    addStocking() {
        const order = this.state.order;
        let max = order.quantityChristmas() * 6;
        if (order.stockingNames.length < max) {
            order.stockingNames[order.stockingNames.length] = '';
            this.setState({order: order});
        }
    }

    updateStocking(index, name) {
        const order = this.state.order;
        order.stockingNames[index] = name;
        this.setState({order: order});
    }

    removeStocking(index) {
        const { order } = this.state;
        const quantityXmas = order.quantityChristmas();
        if (order.stockingNames.length > (2 * quantityXmas)) {
            let deleted = order.stockingNames.splice(index, 1);
            console.log("Removed stocking with name: %s", deleted);
            this.setState({order: order});
        } else {
            console.log("Refusing to delete more stockings.");
        }
    }

    removeItem(item) {
        const cart = this.state.cart;
        cart.removeItem(item);
        const catalog = this.state.catalog;
        catalog[item.sku.id].available += item.quantity;
        this.setState({
            cart: cart,
            catalog: catalog,
            validationError: null,
        });
        const {cookies} = this.props;
        cookies.set('cart', JSON.stringify(cart, filterClassname), { path: '/', maxAge: 604800 });
    }

    changeQuantity(item, quantity) {
        const itemId = CartItem.itemId(item);
        if (item.quantity < quantity) {
            // we're adding an extra. check availability.
            const delta = quantity - item.quantity;
            if (this.state.catalog[item.sku.id].available <= delta) {
                console.log("Refusing to add another item because none are available: %s", item.sku.id);
                return;
            }
            const cart = this.state.cart;
            cart.items[itemId].quantity += delta;
            const catalog = this.state.catalog;
            catalog[item.sku.id].available -= delta;
            this.setState({
                cart: cart,
                catalog: catalog,
                validationError: null,
            });
            const {cookies} = this.props;
            cookies.set('cart', JSON.stringify(cart, filterClassname), { path: '/', maxAge: 604800 });
        } else {
            // we're removing an item.
            const delta = item.quantity - quantity;
            const cart = this.state.cart;
            cart.items[itemId].quantity -= delta;
            const catalog = this.state.catalog;
            catalog[item.sku.id].available += delta;
            this.setState({
                cart: cart,
                catalog: catalog,
                validationError: null,
            });
            const {cookies} = this.props;
            cookies.set('cart', JSON.stringify(cart, filterClassname), { path: '/', maxAge: 604800 });
        }
    }

    acceptAgreement() {
        const {cart} = this.state;
        cart.checkoutCodeAgreed = true;
        this.setState({cart: cart});
    }

    applyCheckoutCode(checkoutCode) {
        const {cart} = this.state;
        let validationError = null;
        console.log("Received code %s", checkoutCode);
        cart.checkoutCodeAgreed = false;
        if (checkoutCode.toUpperCase() === 'PICKUP' || checkoutCode.toUpperCase() === 'BEST TRAINER') {
            cart.checkoutCode = checkoutCode.toUpperCase();
            this.setState({
                cart: cart,
                validationError: null
            });
            console.log("Code was valid: %s", checkoutCode);
        } else {
            cart.checkoutCode = '';
            if (checkoutCode) {
                // if this was not nothing...
                validationError = 'Sorry, that did not look like a valid code.';
            }
            this.setState({
                cart: cart,
                validationError: validationError
            });
            console.log("Code was NOT valid: %s", checkoutCode);
        }
    }

    addItem(item) {
        if (this.state.catalog[item.sku.id].available <= 0) {
            console.log("Refusing to add another item because none are available: %s", item.sku.id);
            return;
        }
        const { cart } = this.state;
        cart.addItem(item);
        const { catalog } = this.state;
        catalog[item.sku.id].available -= 1;
        const { order } = this.state;
        if (item.sku.isChristmas() || item.addStockings) {
            // Add 2 stockings!
            order.stockingNames[order.stockingNames.length] = '';
            order.stockingNames[order.stockingNames.length] = '';
        }
        this.setState({
            cart: cart,
            order: order,
            catalog: catalog,
            validationError: null,
            flyingIn: true,
            flyingOut: false
        });
        const {cookies} = this.props;
        cookies.set('cart', JSON.stringify(cart, filterClassname), { path: '/', maxAge: 604800 });
        setTimeout(() => this.setState({flyingIn: false, flyingOut: true}), 3000);
    }

    toggleShipping() {
        let cart = this.state.cart;
        cart.includeShipping = !cart.includeShipping;
        this.setState({cart: cart});
    }

    updateOrder() {
        this.setState({order: this.state.order});
    }

    slideState() {
        if (this.state.flyingIn) {
            return "slideIn";
        }
        if (this.state.flyingOut) {
            return "slideOut";
        }
        return "hidden";
    }

    render() {
        return (
            <div className="App">
                { this.state.checkoutInProgress ? <div className="spinnerModal"><Loader type="Oval" color="#f0f0f0"  /></div> : '' }
                <div id="slider" className={this.slideState()}>
                    <h2>Item added!</h2>
                    <p>To checkout, please review your cart.</p>
                </div>
                <header className="App-header">
                    <h2>Woody's Custom Woodwork</h2>
                    <div className="floatNav">
                        {this.state.cart.hasItem() ? <Link className="button" to='/cart'><span className="circle">{this.state.cart.countItems()}</span> <i className="fas fa-shopping-cart" /> Cart</Link> : '' }
                    </div>
                </header>
                <Route exact path='/' render={() =>
                <div className="content">
                    <div className="blurb">
                        <img className="carpenter" alt="Custom handmade wood fireplaces" src="images/carpentry.jpg"/>
                        <div className="about"> Handmade, all wood fireplaces made to order. This unique item is custom made. No two are exactly alike.</div>
                    </div>
                    <Catalog catalog={Object.values(this.state.catalog)} cart={this.state.cart} addItem={this.addItem} />
                    <div className="navigation">
                        <a className="button" href="https://www.facebook.com/woodyscustomwoodwork/"><i className="fab fa-facebook-f" /> Facebook Page</a>
                        {this.state.cart.hasItem() ? <Link className="button" to='/cart'><span className="circle">{this.state.cart.countItems()}</span> <i className="fas fa-shopping-cart" /> Cart</Link> : '' }
                    </div>
                </div>
                }/>

                <Route path='/cart' render={() =>
                <div className="content">
                    {this.state.validationError != null?
                        <div className="errorMessage">{this.state.validationError}</div> : ''}
                    <CartComponent cart={this.state.cart}
                                   removeItem={this.removeItem}
                                   toggleShipping={this.toggleShipping}
                                   changeQuantity={this.changeQuantity}
                                   applyCheckoutCode={this.applyCheckoutCode}
                    />
                </div>
                }/>

                <Route path='/checkout' render={() =>
                <div className="content">
                    {this.state.validationError != null?
                        <div className="errorMessage">{this.state.validationError}</div> : ''}
                    <CheckoutComponent checkoutInProgress={this.state.checkoutInProgress}
                                       order={this.state.order}
                                       updateOrder={this.updateOrder}
                                       checkout={this.checkout}
                                       addStocking={this.addStocking}
                                       removeStocking={this.removeStocking}
                                       updateStocking={this.updateStocking}
                    />
                    {this.state.validationError != null?
                        <div className="errorMessage">{this.state.validationError}</div> : ''}
                </div>
                }/>

                <Route path='/item/:skuId' render={(props) =>
                    <div className="content">
                        <DetailComponent {...props}
                                         sku={this.state.catalog[props.match.params.skuId]}
                                         cart={this.state.cart}
                                         addItem={this.addItem} />
                    </div>
                }/>

                <Route path='/video/:skuId' render={(props) =>
                    <div className="content">
                        <VideoComponent {...props}
                                         sku={this.state.catalog[props.match.params.skuId]}
                                         cart={this.state.cart}
                                         addItem={this.addItem} />
                    </div>
                }/>

                <Route path='/orderComplete/:orderId' render={(props) =>
                    <div className="content centered">
                        <h3>Thank you for your order!</h3>
                        <p className="orderId">Order ID: {props.match.params.orderId}</p>
                        <p>
                            You should receive an email confirming your order. If you have any questions, email us or contact us through our <a href="https://www.facebook.com/woodyscustomwoodwork/">Facebook Page</a> or email us at <a href="mailto:woodyscustomwoodwork@gmail.com">woodyscustomwoodwork@gmail.com</a>.
                        </p>
                        <Link to="/">Home</Link>
                    </div>
                }/>

                <Route path='/orderProblem/:orderId' render={(props) =>
                    <div className="content centered">
                        <h3>Looks like there was a problem.</h3>
                        <p className="orderId">Order ID: {props.match.params.orderId}</p>
                        <p>
                            Please let us know what went wrong by contacting us through our <a href="https://www.facebook.com/woodyscustomwoodwork/">Facebook page</a>.
                        </p>
                    </div>
                }/>

                <Route path='/checkoutCodeAgreement' render={() =>
                    <CheckoutAgreement applyCheckoutCode={this.applyCheckoutCode} acceptAgreement={this.acceptAgreement} cart={this.state.cart} />
                }/>

                <Route path='/admin' render={(props) =>
                    <AdminComponent {...props} cookies={this.props.cookies} config={this.state.config} />
                }/>
            </div>
        );
    }
}

export default withRouter(withCookies(Wcw));
