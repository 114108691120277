var _classCallCheck = require("/home/chad.schellenger/Development/shopwcw/ui/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var Address = function Address() {
  "use strict";

  _classCallCheck(this, Address);

  this.to = '';
  this.line1 = '';
  this.line2 = '';
  this.city = '';
  this.state = '';
  this.zip = '';
};

function createAddress(obj) {
  var address = new Address();
  address.to = obj.to;
  address.line1 = obj.line1;
  address.line2 = obj.line2;
  address.city = obj.city;
  address.state = obj.state;
  address.zip = obj.zip;
  return address;
}

function cleanForDynamo(addr) {
  if (addr.to === '') {
    addr.to = null;
  }

  if (addr.line1 === '') {
    addr.line1 = null;
  }

  if (addr.line2 === '') {
    addr.line2 = null;
  }

  if (addr.city === '') {
    addr.city = null;
  }

  if (addr.state === '') {
    addr.state = null;
  }

  if (addr.zip === '') {
    addr.zip = null;
  }
}

module.exports = {
  address: Address,
  createAddress: createAddress,
  cleanForDynamo: cleanForDynamo
};