import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {Cart} from 'wcw-shared';
import DeliveryComponent from "./DeliveryComponent";

class CartItemComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.decreaseQuantity = this.decreaseQuantity.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
    }

    decreaseQuantity() {
        if (this.props.item.quantity === 1) {
            this.props.removeFromCart(this.props.item);
        } else {
            this.props.changeQuantity(this.props.item, this.props.item.quantity - 1);
        }
    }

    increaseQuantity() {
        this.props.changeQuantity(this.props.item, this.props.item.quantity + 1);
    }

    static renderName(item) {
        if (item.addStockings) {
            return item.sku.name + " w/ stockings"
        } else {
            return item.sku.name;
        }
    }

    render() {
        const item = this.props.item;
        const quantity = item.quantity;
        const itemPrice = Cart.itemEachAmount(item);
        const subtotal = Cart.itemAmount(item);
        return (
            <div className="tableRow">
                <div className="tableCell g2">{CartItemComponent.renderName(item)}</div>
                <div className="tableCell hideIfSmall">{Cart.renderPrice(itemPrice)}</div>
                <div className="tableCell noWrap">
                    <i className="fas fa-minus" onClick={this.decreaseQuantity}/>
                    <input className="number" readOnly={true} type="number" value={quantity}  />
                    <i className="fas fa-plus" onClick={this.increaseQuantity}/>
                </div>
                <div className="tableCell alignRight">{Cart.renderPrice(subtotal)}</div>
            </div>
        );
    }
}

class DiscountRow extends Component {
    render() {
        const discount = Cart.discountAmount(this.props.cart);
        if (discount > 0) {
            return (
                <div className="tableRow">
                    <div className="tableCell g4 shrinkIfSmall">Discount</div>
                    <div className="tableCell alignRight">-{Cart.renderPrice(discount)}</div>
                </div>
            );
        } else {
            return '';
        }
    }
}

class TotalRow extends Component{

    render() {
        const item = this.props.item;
        const sku = item.sku;
        const subtotal = sku.price;
        return (
            <div className="tableRow">
                <div className="tableCell g4 shrinkIfSmall">{sku.name}</div>
                <div className="tableCell alignRight">{Cart.renderPrice(subtotal)}</div>
            </div>
        );
    }
}

class CartHeader extends Component {
    render() {
        return (
            <div className="tableRow">
                <div className="tableCell g2 bold">Item name</div>
                <div className="tableCell hideIfSmall bold">Item price</div>
                <div className="tableCell bold">Quantity</div>
                <div className="tableCell bold alignRight">Total</div>
            </div>
        );
    }
}

class CheckoutCode extends Component {

    constructor(props, context) {
        super(props, context);
        this.applyCode = this.applyCode.bind(this);
        this.checkoutCodeChange = this.checkoutCodeChange.bind(this);
        this.enterCode = this.enterCode.bind(this);
        this.removeCode = this.removeCode.bind(this);
        this.state = {
            haveCode: false,
            checkoutCode: ''
        };
    }

    checkoutCodeChange(e) {
        this.setState({
            checkoutCode: e.target.value
        });
    }

    applyCode() {
        this.props.applyCheckoutCode(this.state.checkoutCode);
        this.setState({
            haveCode: false,
            checkoutCode: ''
        });
    }

    enterCode() {
        this.setState({
            haveCode: true
        })
    }

    removeCode() {
        this.props.applyCheckoutCode(this.state.checkoutCode);
        this.setState({
            haveCode: false,
            checkoutCode: ''
        });
    }

    render() {
        if (this.props.checkoutCode) {
            return (
                <div>
                    <button className="link" onClick={this.removeCode}>Remove code</button>
                </div>
            );
        } else if (this.state.haveCode) {
            return (
                <div className="nameAndInventory">
                    <label>Enter code then click 'Apply'</label>
                    <input type="text" value={this.state.checkoutCode} onChange={this.checkoutCodeChange} />
                    <button className="big" onClick={this.applyCode}>Apply</button>
                </div>
            );
        } else {
            return (
                <div>
                    <button className="link" onClick={this.enterCode}>I have a checkout code</button>
                </div>
            );
        }
    }
}

class CartComponent extends Component {

    render() {
        const cart = this.props.cart;
        const items = Object.values(cart.items).map((item, idx) =>
            <CartItemComponent item={item} key={idx} removeFromCart={this.props.removeItem} changeQuantity={this.props.changeQuantity} />);
        const total = cart.totalItem();
        const shipping = cart.shippingItem();

        if (cart.hasItem()) {
            if (cart.checkoutCode && !cart.checkoutCodeAgreed) {
                return <Redirect to='/checkoutCodeAgreement' />;
            }
            return (
                <div className="table cart">
                    <CartHeader />
                    {items}
                    <DiscountRow cart={cart} />
                    <TotalRow item={shipping} />
                    <TotalRow item={total} />
                    <DeliveryComponent cart={cart} />
                    <CheckoutCode applyCheckoutCode={this.props.applyCheckoutCode} checkoutCode={cart.checkoutCode} />
                    <div className="payNav">
                        <div className="tableCell g4 shrinkIfSmall">
                            <Link to='/'>Still shopping</Link>
                        </div>
                        <div className="tableCell g2 alignRight">
                            <Link className="checkout" to='/checkout'>Proceed to checkout</Link>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="centered">
                    <div>Your cart is empty!</div>
                    <Link to='/'>Home</Link>
                </div>
            );
        }
    }
}
export default CartComponent;
