var _classCallCheck = require("/home/chad.schellenger/Development/shopwcw/ui/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var Sku = require('./Sku');

var CartItem = function CartItem(sku, quantity, addStockings) {
  "use strict";

  _classCallCheck(this, CartItem);

  this.sku = sku;
  this.quantity = quantity || 1;
  this.addStockings = addStockings || false;
};

function createCartItem(obj) {
  return new CartItem(Sku.createSku(obj.sku), obj.quantity, obj.addStockings);
}

function itemId(item) {
  return item.sku.id + (item.addStockings ? ":w_stocking" : "");
}

function itemName(item) {
  if (item.addStockings) {
    return item.sku.name + " w/ stockings";
  } else {
    return item.sku.name;
  }
}

module.exports = {
  cartItem: CartItem,
  createCartItem: createCartItem,
  itemId: itemId,
  itemName: itemName
};