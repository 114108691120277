class Config {

    constructor() {
        this.stage = {
            orderEndpoint: "https://n1k5ukvuuj.execute-api.us-east-1.amazonaws.com/stage/order",
            skuEndpoint: "https://n1k5ukvuuj.execute-api.us-east-1.amazonaws.com/stage/sku",
            configStripe: () => window.stripe = window.stripeStage
        };
        this.prod = {
            orderEndpoint: "https://n1k5ukvuuj.execute-api.us-east-1.amazonaws.com/prod/order",
            skuEndpoint: "https://n1k5ukvuuj.execute-api.us-east-1.amazonaws.com/prod/sku",
            configStripe: () => window.stripe = window.stripeProd
        }
    }
}
export default Config;
