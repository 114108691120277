import React, {Component} from 'react';

class StockingItem extends Component {


    constructor(props, context) {
        super(props, context);
        this.updateStockingName = this.updateStockingName.bind(this);
        this.removeStocking = this.removeStocking.bind(this);
    }

    updateStockingName(e) {
        this.props.updateStocking(this.props.index, e.target.value);
    }

    removeStocking() {
        this.props.removeStocking(this.props.index);
    }

    render() {
        return (
            <div className="tableRow">
                <div className="tableCell label">
                    <label>Stocking {this.props.index + 1}:</label>
                </div>
                <div className="tableCell withAction">
                    <input type="text" value={this.props.stockingName} onChange={this.updateStockingName} />
                </div>
                <div className="tableCell tiny">
                    <button tabIndex="-1" className="link" onClick={this.removeStocking}><i className="fas fa-trash" /></button>
                </div>
            </div>
        );
    }
}

class StockingsComponent extends Component {

    render() {
        const order = this.props.order;
        const xmasCount = order.quantityChristmas();
        if (xmasCount < 1) {
            console.log("No christmas items: %s", xmasCount);
            return '';
        }
        let stockingNames = order.stockingNames.map((stockingName, idx) =>
            <StockingItem key={idx}
                          removeStocking={this.props.removeStocking}
                          updateStocking={this.props.updateStocking}
                          index={idx}
                          stockingName={stockingName}/>
        );
        return (
        <div className="table">
            <h3>Stocking Names</h3>
            {stockingNames}
            <div className="tableRow">
                <button className="link" onClick={this.props.addStocking}>Add stocking <i className="fas fa-plus" /></button>
            </div>
        </div>
        );
    }
}
export default StockingsComponent;
