import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Cart} from 'wcw-shared';
import InventoryBanner from "./InventoryBanner";
import ShippingPriceComponent from "./ShippingPriceComponent";

class ItemComponent extends Component{

    constructor(props, context) {
        super(props, context);
        this.addToCart = this.addToCart.bind(this);
    }

    addToCart() {
        this.props.addToCart(this.props.sku);
    }

    render() {
        const sku = this.props.sku;
        return (
        <div className="item" >
            <Link to={'/item/' + sku.id}><img alt={sku.name} src={sku.imgSrc} /></Link>
            <label className="name">{sku.name}</label>
            <div className="priceRow">
                <label className="price">{Cart.renderPrice(sku.price)}</label>
                <button className="addToCart" disabled={sku.available <= 0} onClick={this.addToCart}><i className="fas fa-cart-plus" /> Add</button>
            </div>
            <ShippingPriceComponent />
            <InventoryBanner sku={sku} />
        </div>
        );
    }
}

export default ItemComponent;
