import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Cart} from 'wcw-shared';
import StockingsComponent from "./StockingsComponent";

class ShipToComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateNotes = this.updateNotes.bind(this);
        this.updateTo = this.updateTo.bind(this);
        this.updateLine1 = this.updateLine1.bind(this);
        this.updateLine2 = this.updateLine2.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updateZip = this.updateZip.bind(this);
    }

    updateEmail(e) {
        this.props.order.email = e.target.value;
        this.props.updateOrder();
    }

    updateNotes(e) {
        this.props.order.notes = e.target.value;
        this.props.updateOrder();
    }

    updateTo(e) {
        this.props.order.shippingAddress.to = e.target.value;
        this.props.updateOrder();
    }

    updateLine1(e) {
        this.props.order.shippingAddress.line1 = e.target.value;
        this.props.updateOrder();
    }

    updateLine2(e) {
        this.props.order.shippingAddress.line2 = e.target.value;
        this.props.updateOrder();
    }

    updateCity(e) {
        this.props.order.shippingAddress.city = e.target.value;
        this.props.updateOrder();
    }

    updateState(e) {
        this.props.order.shippingAddress.state = e.target.value;
        this.props.updateOrder();
    }

    updateZip(e) {
        this.props.order.shippingAddress.zip = e.target.value;
        this.props.updateOrder();
    }

    render() {
        const order = this.props.order;
        return (
        <div className="table">
            <h3>Order Information</h3>
            <div className="tableRow">
                <div className="tableCell label">
                    <label>Your name:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.shippingAddress.to} onChange={this.updateTo} />
                </div>
            </div>

            <div className="tableRow">
                <div className="tableCell label">
                    <label>Your email:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.email} onChange={this.updateEmail} />
                </div>
            </div>

            <div className="tableRow">
                <div className="tableCell label">
                    <label>Notes for Woody:</label>
                </div>
                <div className="tableCell">
                    <textarea onChange={this.updateNotes} value={order.notes} />
                </div>
            </div>

            <h3>Shipping Address</h3>
            <div className="tableRow">
                <div className="tableCell label">
                    <label>Street Address:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.shippingAddress.line1} onChange={this.updateLine1} />
                </div>
            </div>
            <div className="tableRow">
                <div className="tableCell label">
                    <label>Street Address 2:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.shippingAddress.line2} onChange={this.updateLine2} />
                </div>
            </div>

            <div className="tableRow">
                <div className="tableCell label">
                    <label>City:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.shippingAddress.city} onChange={this.updateCity} />
                </div>
            </div>

            <div className="tableRow">
                <div className="tableCell label">
                    <label>State:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.shippingAddress.state} onChange={this.updateState} />
                </div>
            </div>

            <div className="tableRow">
                <div className="tableCell label">
                    <label>Zip:</label>
                </div>
                <div className="tableCell">
                    <input type="text" value={order.shippingAddress.zip} onChange={this.updateZip} />
                </div>
            </div>
        </div>
        );
    }
}

class CheckoutComponent extends Component {

    render() {
        const myOrder = this.props.order;
        const myCart = myOrder.cart;
        return (
            <div className="checkout">
                <div className="bold">Total Amount: {Cart.renderPrice(myCart.totalAmount())}</div>
                <StockingsComponent
                    order={myOrder}
                    addStocking={this.props.addStocking}
                    updateStocking={this.props.updateStocking}
                    removeStocking={this.props.removeStocking} />

                <ShipToComponent order={myOrder} updateOrder={this.props.updateOrder} />
                <div className="payNav">
                    <Link className="back" to='/cart'>Back</Link>
                    <button disabled={this.props.checkoutInProgress} className="pay" onClick={this.props.checkout}>Continue to payment</button>
                </div>
            </div>
        )
    }
}
export default CheckoutComponent;
