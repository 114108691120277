import React, {Component} from 'react';

class DeliveryComponent extends Component {

    static renderDeliveryDate(dMin, dMax) {
        const deliveryMin = new Date();
        deliveryMin.setDate(deliveryMin.getDate() + dMin);

        const deliveryMax = new Date();
        deliveryMax.setDate(deliveryMax.getDate() + dMax);

        const options = { month: 'long', day: 'numeric' };

        if (deliveryMin.getMonth() === deliveryMax.getMonth()) {
            return deliveryMin.toLocaleDateString("en-US", options) + " - " + deliveryMax.getDate();
        } else {
            return deliveryMin.toLocaleDateString("en-US", options) + " - " + deliveryMax.toLocaleDateString("en-US", options);
        }
    }

    render() {
        let dMin = 6;
        let dMax = 18;
        const {sku} = this.props;
        if (sku != null) {
            if (sku.deliveryMin > dMin) {
                dMin = sku.deliveryMin;
            }
            if (sku.deliveryMax > dMax) {
                dMax = sku.deliveryMax;
            }
        } else {
            const {cart} = this.props;
            for (const item of Object.values(cart.items)) {
                if (item.sku.deliveryMin > dMin) {
                    dMin = item.sku.deliveryMin;
                }
                if (item.sku.deliveryMax > dMax) {
                    dMax = item.sku.deliveryMax;
                }
            }
        }
        return <div className="deliveryEstimate">Estimated delivery: {DeliveryComponent.renderDeliveryDate(dMin, dMax)} </div>;
    }
}
export default DeliveryComponent;
