var Address = require('./Address');

var Cart = require('./Cart');

var CartItem = require('./CartItem');

var Detail = require('./Detail');

var Order = require('./Order');

var Sku = require('./Sku');

function filterClassname(k, v) {
  if (k.startsWith("_")) {
    return undefined;
  }

  return v;
}

module.exports = {
  Address: Address,
  Cart: Cart,
  CartItem: CartItem,
  Detail: Detail,
  Order: Order,
  Sku: Sku,
  filterClassname: filterClassname
};