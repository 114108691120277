import React, {Component} from 'react';
import {Redirect} from "react-router-dom";

class CheckoutAgreement extends Component {


    constructor(props, context) {
        super(props, context);
        this.acceptAgreement = this.acceptAgreement.bind(this);
        this.removeCode = this.removeCode.bind(this);
    }

    removeCode() {
        this.props.applyCheckoutCode('');
    }

    acceptAgreement() {
        this.props.acceptAgreement();
    }

    render() {
        const {cart} = this.props;
        if (cart.checkoutCode && !cart.checkoutCodeAgreed) {
            return (
                <div className="content centered">
                    <div className="notTooBig">
                        <h3>Checkout Code Agreement</h3>
                        <p>By checking out with this discount code you acknowledge that you will be responsible for picking up your items in person. Your items will not be shipped.</p>
                        <div className="payNav">
                            <button className="link" onClick={this.removeCode}>Cancel</button>
                            <button className="big" onClick={this.acceptAgreement}>Accept</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect to='/cart' />
            )
        }

    }
}
export default CheckoutAgreement;
