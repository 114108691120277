import React, {Component} from 'react';
import {Cart} from 'wcw-shared';

class AdminComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentOrder: null,
            token: '',
            message: null,
            orders: [],
            orderStatus: 'paid',
            tokenVisible: true,
        };
        this.tokenChange = this.tokenChange.bind(this);
        this.fetchPendingOrders = this.fetchPendingOrders.bind(this);
        this.viewDetails = this.viewDetails.bind(this);
        this.completeOrder = this.completeOrder.bind(this);
        this.togglePending = this.togglePending.bind(this);
    }

    tokenChange(e) {
        this.setState({token: e.target.value});
    }

    fetchPendingOrders() {
        fetch(`${this.props.config.orderEndpoint}?status=${this.state.orderStatus}` ,{
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        })
        .then(res => {
            if (res.ok) {
                console.log("Updating security cookie.");
                this.props.cookies.set('token', this.state.token, { path: '/', maxAge: 604800, sameSite: 'strict'});
                this.setState({tokenVisible: false});
                return res.json();
            } else {
                console.log("Got not-OK response");
                this.setState({
                    message: "Error: unable to load orders. Your token is probably incorrect.",
                    tokenVisible: true
                });
                return {Items: []};
            }
        }, err => {
            console.log("Unable to load orders: %s", err.message);
            this.setState({message: err.message});
        })
        .then(res => {
            let orders = res.Items;
            this.setState({
                orders: orders,
                currentOrder: null
            });
        })
    }

    completeOrder(orderId, trackingNumber) {
        fetch(this.props.config.orderEndpoint + "/complete",{
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                orderId: orderId,
                trackingNumber: trackingNumber
            }),
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        }).then(res => {
            if (res.ok) {
                const orders = this.state.orders.filter((order) => order.orderId !== orderId);
                this.setState({
                    orders: orders,
                    currentOrder: null
                });
            } else {
                console.log("Got not-OK response");
                this.setState({message: "Error: Unable to save order. Try again?" });
            }
        }, err => {
            console.log("Unable to load orders: %s", err.message);
            this.setState({message: err.message});
        });
    }

    componentDidMount() {
        const securityCookie = this.props.cookies.get('token') || '';
        if (securityCookie) {
            console.log("Found security cookie");
            this.setState({token: securityCookie || ''});
        } else {
            console.log("No security cookie!");
        }
    }

    static renderDate(date) {
        const options = {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return date.toLocaleDateString("en-US", options)
    }

    static renderDateSmall(date) {
        const options = {
            month: 'long',
            day: 'numeric'
        };
        return date.toLocaleDateString("en-US", options)
    }

    viewDetails(order) {
        this.setState({currentOrder: order});
    }

    togglePending() {
        this.setState({orderStatus: (this.state.orderStatus === 'paid' ? 'shipped' : 'paid')});
    }

    render() {
        const orders = this.state.orders;
        if (this.state.tokenVisible) {
            return (
                <div className="content">
                    <h3>Admin View</h3>
                    {this.state.message ? <div className="errorMessage">{this.state.message}</div> : ''}
                    <div className="table admin">
                        <div className="adminControls">
                            <div className={this.state.tokenVisible ? 'centerVertically' : 'hidden'}>
                                <label className="withPaddingRight">Security token</label>
                                <input type="text" value={this.state.token} onChange={this.tokenChange} />
                            </div>
                            <button className="big" onClick={this.fetchPendingOrders}>Login</button>
                        </div>
                    </div>
                </div>
            )
        }
        const renderedOrders = orders.map((order, idx) =>
            <AdminRowComponent key={idx} order={order} viewDetails={this.viewDetails} />
        );
        return (
            <div className="content">
                <h3>Admin View</h3>
                {this.state.message ? <div className="errorMessage">{this.state.message}</div> : ''}
                <div className="table admin">
                    <div className="adminControls">
                        <div className="centerVertically">
                            <label htmlFor="pendingOrderState">Pending?</label>
                            <input type="checkbox" id="pendingOrderState" checked={this.state.orderStatus === 'paid'} onChange={this.togglePending} />
                        </div>
                        <button className="big" onClick={this.fetchPendingOrders}>List orders</button>
                    </div>
                    {renderedOrders}
                </div>
                { this.state.currentOrder != null ? <OrderDetailsComponent config={this.props.config} order={this.state.currentOrder} completeOrder={this.completeOrder} /> : '' }
            </div>
        );
    }
}

class OrderDetailsComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.trackingNumberChange = this.trackingNumberChange.bind(this);
        this.completeOrder = this.completeOrder.bind(this);
        this.state = {
            trackingNumber: props.order.trackingNumber || ''
        }
    }

    trackingNumberChange(e) {
        this.setState({
            trackingNumber: e.target.value
        });
    }

    completeOrder() {
        this.props.completeOrder(this.props.order.orderId, this.state.trackingNumber);
    }

    render() {
        const { order } = this.props;
        const { cart } = order;
        const stockings = (order.stockingNames || []);
        const renderedStockings = stockings.length > 0 ? <StockingsComponent stockingNames={stockings} /> : '';
        const orderItems = Object.values(cart.items).map((item, idx) => <OrderItemComponent key={idx} item={item}/> );
        return (
            <div className="table admin cart bordered withPadding">
                <h4>Order Details</h4>
                <div>
                    <label className="name inlineBlock withPaddingRight">Order ID:</label>
                    <label>{order.orderId}</label>
                </div>
                <div><label className="name inlineBlock withPaddingRight">Email:</label><a href={`mailto:${order.email}?subject=Woody's order ${order.orderId}`}>{order.email}</a></div>
                <h4>Shipping Information</h4>
                <div>
                    <div>{order.shippingAddress.to}</div>
                    <div>{order.shippingAddress.line1}</div>
                    {order.shippingAddress.line2? <div>{order.shippingAddress.line2}</div> : ''}
                    <div>{order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zip}</div>
                </div>
                <h4>Notes for Woody</h4>
                <div className="withPaddingBottom notes">
                    {order.notes}
                </div>
                <div className="priceRow borderTop">
                    <label>Total Price:</label>
                    <label>{order.totalCost != null? Cart.renderPrice(order.totalCost.sku.price) : Cart.renderPrice(Cart.totalAmount(order.cart)) }</label>
                </div>
                {order.cart.includeShipping ?
                <div className="priceRow">
                    <label>Shipping Price:</label>
                    <label>{order.shippingCost != null? Cart.renderPrice(order.shippingCost.sku.price) : Cart.renderPrice(Cart.shippingItem(order.cart).sku.price)}</label>
                </div>
                : ''}
                {orderItems}
                {renderedStockings}
                <div className="priceRow">
                    <label>Tracking Number</label>
                    <input value={this.state.trackingNumber} onChange={this.trackingNumberChange}/>
                    <button className="big" onClick={this.completeOrder}>Update</button>
                </div>
            </div>
        );
    }
}

class OrderItemComponent extends Component {
    render() {
        const item = this.props.item;
        const sku = item.sku;
        const quantity = item.quantity;
        const subtotal = sku.price * quantity;
        return (
            <div className="tableRow">
                <div className="tableCell g2">{sku.name}</div>
                <div className="tableCell hideIfSmall alignRight">{Cart.renderPrice(sku.price)}</div>
                <div className="tableCell alignRight">{quantity}</div>
                <div className="tableCell alignRight">{Cart.renderPrice(subtotal)}</div>
            </div>
        );
    }
}

class StockingsComponent extends Component {
    render () {
        const stockings = this.props.stockingNames || [];
        const renderedStockings = stockings.map((stockingName, idx) =>
            <li key={idx}>{stockingName}</li>
        );
        return (
            <div>
                <label>Stocking Names</label>
                <ul>
                    {renderedStockings}
                </ul>
            </div>
        );
    }
}

class AdminRowComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.viewDetails = this.viewDetails.bind(this);
    }

    viewDetails() {
        this.props.viewDetails(this.props.order);
    }

    render() {
        const order = this.props.order;
        return (
        <div className="tableRow clickable" onClick={this.viewDetails}>
            <div className="tableCell">{order.orderId}</div>
            <div className="tableCell g3">{order.shippingAddress.to}</div>
            <div className="tableCell">{Cart.renderPrice(Cart.totalAmount(order.cart))}</div>
            <div className="tableCell hideIfSmall">{AdminComponent.renderDate(new Date(order.createdAt * 1000))}</div>
            <div className="tableCell hideIfBig">{AdminComponent.renderDateSmall(new Date(order.createdAt * 1000))}</div>
            <div className="tableCell alignRight">{order.status}</div>
        </div>
        );
    }
}
export default AdminComponent;
